<template>
  <Detail v-if="show" :implementId="implementId" :type="type" :is-edit="true" />
</template>

<script>
import Detail from "./components/Detail";

export default {
  components: { Detail },
  data() {
    return {
      show: false,
      implementId: "",
      type: ""
    };
  },
  created() {
    this.implementId = this.$route.query.implementId;
    this.type = this.$route.query.type;
    this.show = true;
  }
};
</script>
